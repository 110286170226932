<template>
  <div class="table-cell" @dblclick="handleDblClick">
    <span v-if="!editing">
      {{ myValue }}
    </span>
    <input v-else ref="myInput" type="text" :value="myValue" @input="handleInput" @blur="handleBlur">
  </div>
</template>

<script>
import 'animate.css'

export default {
  name: 'EditableTableCell',
  props: {
    type: {
      type: String,
      default: 'string'
    },
    value: {
      type: [Number, String],
      default: ''
    }
  },
  watch: {
    value (newVal) {
      this.myValue = newVal
    }
  },
  data () {
    return {
      editing: false,
      myValue: this.value
    }
  },
  methods: {
    handleDblClick () {
      this.editing = true
      this.$nextTick(() => {
        this.$refs.myInput.focus()
      })
    },
    handleBlur () {
      if (this.type === 'number' && (this.myValue === '' || isNaN(this.myValue))) {
        this.$refs.myInput.classList = 'animated shake fast error'
        setTimeout(() => {
          this.$refs.myInput.classList = ''
        }, 800)
      } else {
        this.$nextTick(() => {
          this.$emit('blur')
          this.editing = false
        })
      }
    },
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style scoped lang="scss">
.table-cell {
  width: 100%;
  height: 100%;
}
.table-cell input {
  width: 100%;
  height: 100%;
  border: 0;
  text-align: center;
  border: 1px solid rgba(#888, .7);
  border-radius: 3px;
  color: #333;
}
.table-cell span {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.table-cell input.error {
  border: 1px solid red;
  color: red;
}
</style>
