<template>
  <div ref="myTable" class="my-table">
    <el-table
      border
      header-cell-class-name="bg-info text-light"
      :height="tableHeight"
      default-expand-all
      :data="list"
      :row-key="rowKey"
      :row-class-name="rowClassName"
      :cell-class-name="cellClassName"
      :cell-style="cellStyle"
      @sort-change="handleSortChange"
      >
      <el-table-column
        v-if="selection"
        align="center"
        fixed="left"
        width="55px">
        <template slot="header" slot-scope="scope">
          <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" :key="scope.id" @change="handleSelectAllChange"></el-checkbox>
        </template>
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-checkbox v-model="scope.row.checked" @change="handleSelectChange(scope.row)"></el-checkbox>
        </template>
      </el-table-column>
      <slot></slot>
    </el-table>
    <div v-if="showFooter" class="footer">
      <slot name="footer"></slot>
    </div>
    <div v-show="showPager" class="pager" ref="pager">
      <div class="pager-text">
        <slot name="pagerText"></slot>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="pageIndex"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyElTable',
  props: {
    myTableHeight: {
      type: Number,
      default: null
    },
    keyFiled: {
      type: String,
      default: 'id'
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    showPager: {
      type: Boolean,
      default: true
    },
    selection: {
      type: Boolean,
      default: false
    },
    selectedValues: {
      type: Array,
      default () {
        return []
      }
    },
    total: {
      type: Number,
      default: 0
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rowKey: {
      type: String,
      default: ''
    },
    pageIndex: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 15
    },
    rowClassName: {
      type: Function,
      default () {
        return () => {
          return ''
        }
      }
    },
    cellClassName: {
      type: Function,
      default () {
        return () => {
          return ''
        }
      }
    },
    cellStyle: {
      type: Function,
      default () {
        return () => {
          return ''
        }
      }
    }
  },
  data () {
    return {
      pageSizes: [15, 30, 50, 100, 500, 1000],
      checkAll: false,
      isIndeterminate: false,
      mySelectedValues: this.selectedValues,
      tableHeight: null
    }
  },
  computed: {
    list () {
      let len = this.pageSize - this.data.length
      if (len > 0) {
        return this.data.map((item, index) => {
          item.index = this.pageSize * (this.pageIndex - 1) + index + 1
          return item
        })
      }
      return this.data.map((item, index) => {
        item.index = this.pageSize * (this.pageIndex - 1) + index + 1
        return item
      })
    }
  },
  watch: {
    mySelectedValues (newVal) {
      this.checkAll = this.mySelectedValues.length === this.data.length
      this.isIndeterminate = this.mySelectedValues.length > 0 && this.mySelectedValues.length < this.data.length
      this.$emit('selection-change', newVal)
    }
  },
  methods: {
    handleSizeChange (val) {
      this.$emit('size-change', val)
    },
    handleCurrentChange (val) {
      this.$emit('current-change', val)
    },
    handleSortChange (event) {
      this.$emit('sort-change', event)
    },
    handleSelectChange (row) {
      let index = this.mySelectedValues.indexOf(row[this.keyFiled])
      if (row.checked && index === -1) {
        this.mySelectedValues.push(row[this.keyFiled])
      } else if (!row.checked && index !== -1) {
        this.mySelectedValues.splice(index, 1)
      }
    },
    handleSelectAllChange () {
      this.$emit('select-all-change', this.checkAll)
      this.mySelectedValues = []
      if (this.checkAll) {
        this.data.forEach(item => {
          this.mySelectedValues.push(item[this.keyFiled])
        })
      }
      this.isIndeterminate = this.mySelectedValues.length > 0 && this.mySelectedValues.length < this.data.length
    },
    computeTableHeight () {
      this.tableHeight = this.myTableHeight | 600
    }
  },
  mounted () {
    this.computeTableHeight()
    if (this.myTableHeight) {
      this.tableHeight = this.myTableHeight
    }
  }
}
</script>

<style scoped lang="scss">
.my-table {
  width: 100%;
}
.el-table {
  width: 100%;
}
.pager {
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}
.pager-fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 100%;
  text-align: right;
  background-color: rgba(255, 255, 255, .6);
}
.pager-text {
  padding-top: 3px;
  color: #606266;
  flex: 1;
  display: flex;
  align-items: space-between;
  justify-content: space-between;
}
.footer {
  border: 1px solid rgb(227, 230, 236);
  line-height: 40px;
  font-size: 15px;
  font-weight: bold;
  background-color: rgba(#eee, 1);
  padding: 0 10px;
  text-align: right;
}
</style>
