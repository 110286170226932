import BaseService from '@/services/BaseService'

let resourceName = 'a25s'

class A25Service extends BaseService {
}

const a25Service = new A25Service(resourceName)

export default a25Service
