<template>
  <div class="examination-detail">
    <div class="animated fadeIn slow title">
      {{ title }}
    </div>
    <el-tabs
      v-model="activeTab"
      >
     <el-tab-pane
        label="其它"
        name="other">
        <a25-index></a25-index>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import A25Index from '@/views/ExaminationManage/Tabs/LabA25Index'
import 'animate.css'

export default {
  name: 'ExaminationDetail',
  components: {
    A25Index
  },
  computed: {
    title () {
      return `${this.tenderName}标段 第${this.periodName}期 编号：${this.examinationCode}`
    },
    ...mapGetters('examination', {
      tenderName: 'getTenderName',
      periodName: 'getPeriodName',
      examinationCode: 'getExaminationCode'
    })
  },
  data () {
    return {
      activeTab: 'other'
    }
  }
}
</script>

<style scoped lang="scss">
.examination-detail {
  position: relative;
  min-width: 780px;
}
.title {
  position: absolute;
  line-height: 40px;
  font-size: 14px;
  top: 0;
  right: 0;
  padding-right: 5px;
  color: red;
}
</style>
